import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Configure from './Configure';
import CreateProgress from './CreateProgress';
import BotResult from './BotResult';
import colors from './colors';
import Botty from './icons/Botty';
import './App.css';

const { primary, white } = colors;

const styles = {
  app: {
    padding: 24,
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: primary,
    height: 100,
    alignItems: 'center',
  },
  botty: {
    width: 65,
    height: 65,
    padding: 8,
    boxSizing: 'border-box',
    backgroundColor: white,
    borderRadius: '50%',
  },
  title: {
    backgroundColor: primary,
    color: white,
    fontSize: 24,
    lineHeight: 1.2,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  headerContent: {
    textAlign: 'center',
  },
  subtitle: {
    color: white,
    fontWeight: 300,
  },
};
const whitelabel = (process.env.REACT_APP_WHITELABEL === 'true');

function App({ classes }) {
  return (
    <Router basename=".">
      <Container maxWidth="md" style={{marginLeft: "25px"}} className={classes.app}>
        <Typography variant="h4">
          Virtual Assistant
        </Typography>
        <br />
        <Typography variant="subtitle1">
          Configure your virtual assistant to embed a pre-configured & trained chatbot capable of handling common asks of HR & IT helpdesks, with pre-built integrations into systems like ServiceNow, Salesforce and SuccessFactors.
        </Typography>
        <br />
        <Card raised>
          { !whitelabel &&
          <div className={classes.cardHeader}>
            <Botty className={classes.botty} fill={primary} />
            <div className={classes.titleWrapper}>
              <Typography className={classes.title}>
                ServisBOT Quickstart
              </Typography>
              <Typography className={classes.subtitle}>
                Ready to create your customized bot?
              </Typography>
            </div>
          </div>
          }
          <CardContent>
            <Switch>
              <Route path="/create">
                <CreateProgress />
              </Route>
              <Route path="/create?mocks=true">
                <CreateProgress />
              </Route>
              <Route path="/bot">
                <BotResult />
              </Route>
              <Route path="/">
                <Configure />
              </Route>
            </Switch>
          </CardContent>
        </Card>
      </Container>
    </Router>
  );
}
export default withStyles(styles)(App);
